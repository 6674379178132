import * as React from "react"
// import ThreeSixty from "react-360-view"
import Tridi from "react-tridi"
import "react-tridi/dist/index.css"

import Layout from "../components/layout"
import Seo from "../components/seo"
import ImageView from "../components/imageView"
import useWindowSize from "../hooks/useWindowSize"

const IndexPage = () => {
  const [showHint, setShowHint] = React.useState(true)
  const tridiRef = React.useRef(null)
  const windowSize = useWindowSize()
  const [loadPercentage, setLoadPercentage] = React.useState(0)
  const [zoomLevel, setZoomLevel] = React.useState(1)
  const renderedImageHeight = windowSize.width
  const initialScale =
    renderedImageHeight < windowSize.height
      ? windowSize.height / renderedImageHeight
      : 1
  React.useEffect(() => {
    if (tridiRef.current != null) {
      tridiRef.current.setZoom(zoomLevel)
    }
  }, [tridiRef.current, zoomLevel])

  // React.useEffect(() => {
  //   setZoomLevel(initialScale)
  // }, [initialScale])

  // if (windowSize.width == undefined) {
  //   return <></>
  // }

  return (
    <Layout
      links={[
        { text: "Facilities Tour", to: "/tour/facilities" },
        { text: "Type C2 Tour", to: "/tour/type-c2" },
        { text: "Type E2 Tour", to: "/tour/type-e2" },
        { text: "Type G Tour", to: "/tour/type-g" },
      ]}
    >
      <Seo title="Home" />
      {/* <ImageView
      // controlPre={
      //   <>
      //     <li>
      //       <button
      //         style={{ backgroundColor: "#968f75" }}
      //         className="bg-cover bg-[url('/images/bg.jpg')] hover:bg-none backdrop-filter backdrop-blur-sm bg-opacity-50 p-1 text-primary hover:text-black"
      //         onClick={() => tridiRef.current.prev()}
      //       >
      //         <svg width={32} height={32} viewBox="0 0 24 24">
      //           <path
      //             fill="currentColor"
      //             d="M18.885 3.515c-4.617-4.618-12.056-4.676-16.756-.195l-2.129-2.258v7.938h7.484l-2.066-2.191c2.82-2.706 7.297-2.676 10.073.1 4.341 4.341 1.737 12.291-5.491 12.291v4.8c3.708 0 6.614-1.244 8.885-3.515 4.686-4.686 4.686-12.284 0-16.97z"
      //           />
      //         </svg>
      //       </button>
      //     </li>
      //     <li>
      //       <button
      //         style={{ backgroundColor: "#968f75" }}
      //         className="bg-cover bg-[url('/images/bg.jpg')] hover:bg-none backdrop-filter backdrop-blur-sm bg-opacity-50 p-1 text-primary hover:text-black"
      //         onClick={() => tridiRef.current.next()}
      //       >
      //         <svg width={32} height={32} viewBox="0 0 24 24">
      //           <path
      //             fill="currentColor"
      //             d="M5.115 3.515c4.617-4.618 12.056-4.676 16.756-.195l2.129-2.258v7.938h-7.484l2.066-2.191c-2.82-2.706-7.297-2.676-10.073.1-4.341 4.341-1.737 12.291 5.491 12.291v4.8c-3.708 0-6.614-1.244-8.885-3.515-4.686-4.686-4.686-12.284 0-16.97z"
      //           />
      //         </svg>
      //       </button>
      //     </li>
      //   </>
      // }
      > */}
      {/* <ul
        id="controls"
        className="fixed bottom-0 left-4 md:left-16 z-20 space-y-2 pb-8"
      >
        <li>
          <button
            style={{ backgroundColor: "#968f75" }}
            className="bg-cover bg-[url('/images/bg.jpg')] hover:bg-none backdrop-filter backdrop-blur-sm bg-opacity-50 p-1 text-primary hover:text-black"
            onClick={() => setZoomLevel(zoomLevel >= 3 ? 3 : zoomLevel + 1)}
          >
            <svg
              width={32}
              height={32}
              viewBox="0 0 32 32"
              xmlSpace="preserve"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill="currentColor"
                d="m27.414 24.586-5.077-5.077A9.932 9.932 0 0 0 24 14c0-5.514-4.486-10-10-10S4 8.486 4 14s4.486 10 10 10a9.932 9.932 0 0 0 5.509-1.663l5.077 5.077a2 2 0 1 0 2.828-2.828zM7 14c0-3.86 3.14-7 7-7s7 3.14 7 7-3.14 7-7 7-7-3.14-7-7z"
              />
              <path
                fill="currentColor"
                d="M19 14a1 1 0 0 1-1 1h-3v3a1 1 0 0 1-2 0v-3h-3a1 1 0 0 1 0-2h3v-3a1 1 0 0 1 2 0v3h3a1 1 0 0 1 1 1z"
              />
            </svg>
          </button>
        </li>
        <li>
          <button
            style={{ backgroundColor: "#968f75" }}
            className="bg-cover bg-[url('/images/bg.jpg')] hover:bg-none backdrop-filter backdrop-blur-sm bg-opacity-50 p-1 text-primary hover:text-black"
            onClick={() => setZoomLevel(zoomLevel <= 1 ? 1 : zoomLevel - 1)}
          >
            <svg
              width={32}
              height={32}
              viewBox="0 0 32 32"
              xmlSpace="preserve"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill="currentColor"
                d="m27.414 24.586-5.077-5.077A9.932 9.932 0 0 0 24 14c0-5.514-4.486-10-10-10S4 8.486 4 14s4.486 10 10 10a9.932 9.932 0 0 0 5.509-1.663l5.077 5.077a2 2 0 1 0 2.828-2.828zM7 14c0-3.86 3.14-7 7-7s7 3.14 7 7-3.14 7-7 7-7-3.14-7-7z"
              />
              <path
                fill="currentColor"
                d="M19 14a1 1 0 0 1-1 1h-8a1 1 0 0 1 0-2h8a1 1 0 0 1 1 1z"
              />
            </svg>
          </button>
        </li>
      </ul> */}
      {/* <div className="flex items-center justify-center"> */}
      {loadPercentage < 100 && (
        <div className="fixed inset-0 z-10  cursor-grab text-center flex flex-col justify-center items-center bg-black text-white backdrop-filter backdrop-blur-xs bg-opacity-50">
          <div>{loadPercentage}% loaded</div>
          <div className="text-xs text-white/50 mt-2">Please wait</div>
        </div>
      )}
      {loadPercentage >= 100 && showHint && (
        <div
          className="fixed inset-0 z-10  cursor-grab text-center flex flex-col justify-center items-center bg-black text-white backdrop-filter backdrop-blur-xs bg-opacity-50"
          onClick={() => setShowHint(false)}
        >
          <div>You may swipe left or right to view different angle</div>
          <div className="text-xs text-white/50 mt-2">
            Click to dismiss this message
          </div>
        </div>
      )}
      <ScalingWrap initialScale={initialScale}>
        {typeof window !== "undefined" && (
          <Tridi
            className="object-contain md:object-cover min-h-screen h-full min-w-screen w-full aspect-square"
            ref={tridiRef}
            // location="images/360"
            location="https://cdn.innovten.com/assets/digimirage"
            format="jpg"
            count="46"
            // onDragStart={() => console.log("started")}
            // autoplay={true}
            // autoplaySpeed={240}
            // onRecordStart={recordStartHandler}
            // onRecordStop={recordStopHandler}
            // onPinClick={pinClickHandler}
            // showControlBar
            onLoadChange={(load_success, percentage) => {
              console.log("onLoadChange", load_success, percentage)
              if (load_success) {
                setLoadPercentage(100)
              } else {
                setLoadPercentage(percentage)
              }
            }}
          />
        )}
      </ScalingWrap>
    </Layout>
  )
}

const ScalingWrap = props => {
  if (props.initialScale > 1) {
    return (
      <div className="flex items-center justify-center">
        <div style={{ scale: props.initialScale }}>{props.children}</div>
      </div>
    )
  }
  return props.children
}

export default IndexPage
